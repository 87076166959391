import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import { title } from '@core/utils/filter';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useStatement() {
  // Use toast
  const toast = useToast();

  const accountNameSelected = ref(null);
  const statementHoldings = ref([]);
  const statementEntries = ref([]);
  const startDate = ref(null);
  const endDate = ref(null);
  const singleAccountNumber = ref([]);
  const reversalsCheck = ref(false);
  const emptyCheck = ref(false);
  const tradeDateCheck = ref(false);
  const loading = ref(false);

  const refetchData = () => {
    //refUserListTable.value.refresh();
    fetchStatementHoldings();
    fetchStatementEntriesForAccountNumber();
  };

  watch([accountNameSelected, startDate, endDate, singleAccountNumber, reversalsCheck, emptyCheck, tradeDateCheck], () => {
    if (accountNameSelected.value == null) {
      statementHoldings.value = [];
      statementEntries.value = [];
      startDate.value = null;
      endDate.value = null;
      reversalsCheck.value = false;
      emptyCheck.value = false;
      tradeDateCheck.value = false;

      //  accountNameSelected.value = [];
    } else {
      refetchData();
    }
  });

  const fetchStatementHoldings = (ctx, callback) => {
    loading.value = true;

    /*  store
      .dispatch('statement/getStatementHoldings', {
        userName: JSON.parse(localStorage.getItem('userData')).username,
        password: JSON.parse(localStorage.getItem('userData')).password,
        accountName: accountNameSelected.value[0].AccountName,
        accountNumber: accountNameSelected.value[0].AccountNumber,
        dateStart: startDate.value,
        dateEnd: endDate.value,
      })
      .then((response) => {
        //  callback(response.data);
        statementHoldings.value = response.data;
        loading.value = false;
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });*/
  };

  const fetchStatementEntriesForAccountNumber = (ctx, callback) => {
    loading.value = true;

    store
      .dispatch('statement/getStatementEntriesForAccountNumber', {
        accountName: accountNameSelected.value.AccountName,
        accountNumber: accountNameSelected.value.AccountNumber,
        dateStart: startDate.value,
        dateEnd: endDate.value,
        singleAccountNumber: singleAccountNumber.value,
        reversalsCheck: reversalsCheck.value,
        emptyCheck: emptyCheck.value,
        tradeDateCheck: tradeDateCheck.value,
      })
      .then((response) => {
        //  callback(response.data);


        statementHoldings.value = response.data;

        if (singleAccountNumber.value.length > 0) {
          statementEntries.value = [];
          response.data.forEach((statement) => {
            singleAccountNumber.value.forEach((element) => {
              if (element == statement.AccountCode) {
                statementEntries.value.push(statement);
              }
            });
          });
        } else {
          statementEntries.value = response.data;
        }

        /*singleAccountNumber.forEach((element) => {
          response.data.forEach((statement) => {
            statementEntries.value.push(statement);
          });
        });*/

        /* this.singleAccountNumber.forEach((element) => {
          // statementEntries.value = response.data;
           response.data.forEach((statement) => {
            console.log(statement);
            // statementEntries.value.push(statement);
          });
        });*/

        loading.value = false;
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchStatementHoldings,
    fetchStatementEntriesForAccountNumber,
    accountNameSelected,
    statementHoldings,
    statementEntries,
    loading,
    startDate,
    endDate,
    singleAccountNumber,
    reversalsCheck,
    emptyCheck,
    tradeDateCheck,
    refetchData,
  };
}
