<template>
  <div>
    <b-overlay :show="downloadPDFloading" rounded="sm" opacity="0.2" no-fade>
    <b-card :title="$t('statement.titles.statement')">
      <b-card-body>
        <b-row>
          <b-col cols="12" md="6" xl="4">
            <label>Account Name</label>
            <v-select class="vue_select_hight" label="AccountName" v-model="accountNameSelected" :options="accountNames" />
          </b-col>
          <b-col cols="12" md="6" xl="4">
            <label>Start Date </label>
            <b-form-datepicker v-model="startDate" placeholder="Choose a start date" :start-weekday="1" :disabled="accountNameSelected == null" :hide-header="true" class="form-control " />
          </b-col>
          <b-col cols="12" md="6" xl="4">
            <label>End Date </label>
            <b-form-datepicker v-model="endDate" placeholder="Choose a end date" :start-weekday="1" :disabled="startDate == null || startDate == ''" :hide-header="true" :min="startDate" class="form-control" />
          </b-col>

          <b-col cols="6" md="2" xl="2" sm="4" class="mt-2 d-flex justify-content-center align-items-center">
            <label class="mr-2 text-nowrap">Trade Date Valuation </label>
            <b-form-checkbox checked="true" v-model="tradeDateCheck" class="custom-control-dark " name="check-button" switch>
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-col>

          <b-col cols="6" md="2" xl="2" sm="4" class="mt-2 d-flex justify-content-center align-items-center">
            <label class="mr-2 text-nowrap">Include Reversals </label>

            <b-form-checkbox checked="true" v-model="reversalsCheck" class="custom-control-dark  " name="check-button" switch>
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-col>
          <b-col cols="6" md="2" xl="2" sm="4" class="mt-2 d-flex justify-content-center align-items-center">
            <label class="mr-2 text-nowrap">Display Empty Accounts </label>
            <b-form-checkbox checked="true" v-model="emptyCheck" class="custom-control-dark " name="check-button" switch>
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-col>

          <b-col cols="6" md="6" xl="6" sm="6" class="mt-2 text-right">
            <b-overlay :show="loading" class="d-inline-block" spinner-small rounded="sm">
              <b-button v-if="statementEntries.length > 0" v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="downloadPdf(statementEntries)" variant="outline-primary">
                <feather-icon icon="ArrowDownIcon" class="mr-50" />
                <span class="align-middle">PDF Download</span>
              </b-button>
            </b-overlay>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <template #overlay v-if="downloadPDFloading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />
              {{
                $t(
                  "general_text.download_pdf"
                )
              }}
            </p>
          </div>
        </template>
      </b-overlay>
    <b-overlay :show="loading" rounded="sm" opacity="0.6" no-fade>
      <b-row class="match-height mt-2" v-if="statementHoldings.length > 0">
        <b-col md="3" lg="2" cols="12">
          <b-row>
            <b-col cols="12" @click="clickStatementAll()" class="cursor-pointer">
              <statistic-card-statement :class="accountClass ? 'account_card' : ''" :statistic="accountNameSelected.AccountName" :statistic-title="accountNameSelected.AccountNumber" :color="tabColor" />
            </b-col>
            <b-col cols="12" v-for="(fetch, index) in statementHoldings" @click="clickStatement(fetch.AccountCode, index)" class="cursor-pointer" :key="index">
              <statistic-card-statement
                :statistic="fetch.Underlying.toUpperCase()"
                :statistic-title="fetch.AccountCode.toUpperCase()"
                :startAmount="fetch.TotalAmountDateStart ? fetch.TotalAmountDateStart.toString() : ''"
                :endAmount="fetch.TotalAmountDateEnd ? fetch.TotalAmountDateEnd.toString() : ''"
                :totalAmount="fetch.TotalAmountF ? fetch.TotalAmountF.toString() : ''"
                :totalAmountShowStart="startDate != null ? startDate : null "
                :totalAmountShowEnd="endDate != null ? endDate : null "
                :statistic-total="fetch.TotalAmountF ? fetch.TotalAmountF.toString() : ''"
                :underlying="fetch.AccountCcy"
                :color="singleAccountNumber.indexOf(fetch.AccountCode) != -1 ? 'light-success' : 'white'"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col md="9" lg="10" cols="12">
          <b-row>
            <b-col cols="12">
              <section id="card-actions">
                <b-row>
                  <!-- card actions -->

                  <b-col cols="12">
                    <b-card-actions v-for="(statement, index) in statementEntries" :key="index" action-collapse :title="statement.AccountCode + ' - ' + statement.Underlying">
                      <b-row>
                        <b-col cols="12">
                          <b-table :items="statement.statements" :fields="fields" :small="true" :hover="true" :striped="true" :fixed="false" :bordered="true" headVariant="dark" table-variant="none" :tbody-tr-class="rowClass" responsive>
                            <!--     fields: ['SFC_Uti', 'Date', 'Related Uti', 'Price', 'Type', 'Description', 'Currency', 'Amount', 'Balance'], -->
                            <template #cell(SFC_Uti)="data">
                              <div class="text-left">
                                {{ data.item.TcUti }}
                              </div>
                            </template>

                            <template v-if="tradeDateCheck" #cell(Date)="data">
                              <div class="text-left text-nowrap">
                                {{ shortDateValue(data.item.TradeDate) }}
                              </div>
                            </template>

                            <template v-else #cell(Date)="data">
                              <div class="text-left text-nowrap">
                                {{ shortDateValue(data.item.ValueDate) }}
                              </div>
                            </template>

                            <template #cell(Related_Uti)="data">
                              <div class="text-left">
                                {{ data.item.RelatedTcUti }}
                              </div>
                            </template>

                            <template #cell(Price)="data">
                              <div class="text-left" v-b-tooltip.hover.bottom="data.item.Price">
                                {{ moneyFixed(data.item.Price) }}
                              </div>
                            </template>

                            <template #cell(Type)="data">
                              <div class="text-left">
                                {{ data.item.TransactionType }}
                              </div>
                            </template>

                            <template #cell(Description)="data">
                              <div class="text-left">
                                {{ data.item.EntryDescription }}
                              </div>
                            </template>

                            <template #cell(Currency)="data">
                              <div class="text-left">
                                {{ data.item.Underlying }}
                              </div>
                            </template>

                            <template #cell(Amount)="data">
                              <div class="text-left">
                                <span v-if="data.item.Amount >= 0" v-b-tooltip.hover.bottom="data.item.Amount" class="text-success">{{ moneyFixed(data.item.Amount) }}</span>
                                <span v-if="data.item.Amount < 0" v-b-tooltip.hover.bottom="data.item.Amount" class="text-danger">{{ moneyFixed(data.item.Amount) }}</span>
                              </div>
                            </template>

                            <template #cell(Balance)="data">
                              <div class="text-left">
                                <span v-if="data.item.balance >= 0" v-b-tooltip.hover.bottom="data.item.balance" class="text-success">{{ moneyFixed(data.item.balance) }}</span>
                                <span v-if="data.item.balance < 0" v-b-tooltip.hover.bottom="data.item.balance" class="text-danger">{{ moneyFixed(data.item.balance) }}</span>
                              </div>
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                    </b-card-actions>
                  </b-col>
                </b-row>
              </section>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <template #overlay v-if="loading">
          <div
            class="text-center d-flex justify-content-center align-items-start mt-3"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />

              {{
                 $t(
                  "general_text.report_preparing"
                ) 
              }}
            </p>
          </div>
        </template>
    </b-overlay>

    
  </div>
</template>

<script>
import useStatementList from './useStatementList';
import { BIcon, BButton, BFormCheckbox, BCalendar, VBTooltip, BTable, BOverlay, BBadge, BPagination, BFormSelect, BFormDatepicker, BCardBody, BCardHeader, BCardText, BCard, BRow, BCol } from 'bootstrap-vue';
import store from '@/store';
import { avatarText } from '@core/utils/filter';
import { onUnmounted } from '@vue/composition-api';
import statementStoreModule from './statementStoreModule';
import SettingsCard from '@core/components/statistics-cards/SettingsCard.vue';
import vSelect from 'vue-select';

import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import StatisticCardStatement from '@core/components/statistics-cards/StatisticCardStatement.vue';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import moment from 'moment';
import jsPDF from 'jspdf';
import VueHtml2pdf from 'vue-html2pdf';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BIcon,
    BButton,
    BCardActions,
    BTable,
    BCalendar,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BCardHeader,
    VueHtml2pdf,
    jsPDF,
    vSelect,
    SettingsCard,
    BFormDatepicker,
    BFormCheckbox,

    BFormSelect,
    BPagination,
    BBadge,
    BCardCode,

    StatisticCardStatement,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  setup() {
    const STATEMENT_APP_STORE_MODULE_NAME = 'statement';
    // Register module
    if (!store.hasModule(STATEMENT_APP_STORE_MODULE_NAME)) store.registerModule(STATEMENT_APP_STORE_MODULE_NAME, statementStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STATEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(STATEMENT_APP_STORE_MODULE_NAME);
    });

    const { tradeDateCheck, emptyCheck, reversalsCheck, singleAccountNumber, startDate, endDate, statementHoldings, statementEntries, accountNameSelected, fetchStatementHoldings, fetchStatementEntriesForAccountNumber, loading } = useStatementList();

    return {
      fetchStatementHoldings,
      fetchStatementEntriesForAccountNumber,
      startDate,
      endDate,
      accountNameSelected,
      statementHoldings,
      statementEntries,
      loading,
      singleAccountNumber,
      reversalsCheck,
      emptyCheck,
      tradeDateCheck,

      // Filter
    };
  },

  data() {
    var accountNames = [];

    return {
      accountNames,
      fields: ['SFC_Uti', 'Date', 'Related_Uti', 'Price', 'Type', 'Description', 'Currency', 'Amount', 'Balance'],
      accountClass:true,
      downloadPDFloading:false,
    };
  },

  watch: {
    /* singleAccountNumber: function(val) {
      console.log(val);
    },*/
  },

  created() {
    this.getAccountNames();
  },

  methods: {
    getAccountNames(data) {
      store.dispatch('statement/getAccountNames', data).then((res) => {
        this.accountNames = res.data.resData;
      });
    },

    shortDateValue(dateValue) {
      if (dateValue != '') {
        return moment(dateValue).format('DD-MMM-YY');
      } else {
        return null;
      }
    },

    rowClass(item, type) {
      const colorClass = 'table-info';
      if (!item || type !== 'row') {
        return;
      }

      // eslint-disable-next-line consistent-return
      if (item.EntryReversed === '1') {
        return colorClass;
      }
    },

    moneyFixed(value) {
      if (value != null && value != '') {
        let val = (value / 1).toFixed(2);
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        return '0.00';
      }
    },

    downloadPdf(data) {
      this.downloadPDFloading = true
      console.log(this.downloadPDFloading)
      //  console.log(this.$refs.downdloadToPdf.innerHTML);
      //const doc = new jsPDF();
      //pdfDownload

      data[0]['start'] = this.startDate;
      data[0]['end'] = this.endDate;

      store.dispatch('statement/downloadPdfGet', data).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.accountNameSelected.AccountName + ' Report.pdf');
        document.body.appendChild(link);
        link.click();
        this.downloadPDFloading = false
      });
    },

    clickStatement(value, index) {
      this.accountClass = false
      const indexNumber = this.singleAccountNumber.indexOf(value);
      if (indexNumber == -1) {
        this.singleAccountNumber.push(value);
      } else {
        this.singleAccountNumber.splice(indexNumber, 1);
      }

      /*   if(this.singleAccountNumber.find((x) => x == value) == undefined){
                this.singleAccountNumber.push(value);
      }else{

      }
      */
    },

    clickStatementAll(value) {
      this.accountClass = true
      this.singleAccountNumber = [];
    },
  },

  computed: {
    tabColor: function() {
      if (this.singleAccountNumber.length == 0) {
        return 'light-info';
      } else {
        return 'white';
      }
    },
  },
};
</script>


<style>
.account_card  {
  border: 1px dotted cyan;
}

.vue_select_hight .vs__dropdown-toggle {
  max-height:39.59px !important;
  height:39.59px !important;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
