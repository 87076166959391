import axios from 'axios';
import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAccountNames(ctx, queryParams) {
      if (ctx.rootState.user.role == 'supermanager') {
        return new Promise((resolve, reject) => {
          axiosIns
            .get('selectAccountInfo')
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      } else {
        return new Promise((resolve, reject) => {
          axiosIns
            .get('selectClientAccountInfo')
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      }
    },

    getStatementEntriesForAccountNumber(ctx, queryParams) {
      if (ctx.rootState.user.role == 'supermanager') {
        return new Promise((resolve, reject) => {
          axiosIns
            .post('getStatementEntriesForAccountNumber', queryParams)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      } else {
        return new Promise((resolve, reject) => {
          axiosIns
            .post('getStatementEntriesForAccountNumberClient', queryParams)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      }
    },

    getStatementHoldings(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getStatementHoldings', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    downloadPdfGet(ctx, datas) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('downloadPdfStatement', { data: datas }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
